body {
  background-color: rgb(128, 128, 128);
  margin: 0; 
  padding: 0; 
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
}

.extra-top-margin {
  padding-top: 15vh;
}