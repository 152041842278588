.next-button {
  background-color: #39FF14; 
  border: 2px solid black; 
  color: black; 
  font-size: 2vw; 
  cursor: pointer; 
  outline: none; 
  font-weight: bold;
  bottom: 0;
  position: fixed;
  white-space: nowrap;
}

.error-flex {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  right: 1%;
  bottom: 0;
  z-index: 1;
  margin-bottom: 50px;
}

.next-flex {
  text-align: right;
}