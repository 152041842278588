.instruction-box {
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 10px;
  margin: auto 90px 3.5vh 90px;
  padding: 5px 10px 5px 10px;
  font-size: 2.8vw;
  font-weight: bold;
  color: #000000;
  text-align: center; 
  word-wrap: break-word; 
}