.tabcode-container {
  background-color: white;
  border: 2px solid black;
  border-radius: 10px;
  padding: 20px;
  width: 40%;
  margin: 0 auto;
  text-align: center;
}

.tabcode {
  font-size: 50px;
  font-weight: bold;
}
  