.word-button {
  display: flex;
  justify-content: center; 
  align-items: center; 
  justify-self: center;
  /*text-transform: uppercase;*/
  background-color: white;
  border: 5px solid black;
  color: #000000;
  font-size: 2.4vw;
  cursor: pointer;
  outline: none;
  white-space: nowrap;
  font-weight: bold;
  padding-top: 2vw;
  padding-bottom: 2vw;
  padding-left: 9vw;
  padding-right: 9vw;
}

.word-button.clicked,
.word-button.selected {
  background-color: #85c7ff; 
}

/*.word-button:hover {
  background-color: #85c7ff;
}*/