/* LandingPage.css */

.landing-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  padding: 3vw;
  box-sizing: border-box;
}

.top-section {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}

.logo {
  width: 30vw;
  height: auto;
  margin-left: 15vw;
  margin-right: 1vw;
}

.welcome-text {
  flex: 2;
}

.welcome-text h1,
.welcome-text h2 {
  text-align: left;
  margin-top: 10px;
}

.info-box {
  text-align: left;
  max-width: 30vw;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}

.show-more {
  color: blue;
  cursor: pointer;
  display: inline-block;
  margin-top: 10px;
}

.search-bar-container {
  width: 20vw;
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.search-bar {
  width: 100%;
  /* height: 50px; */
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.search-button {
  height: 50px;
}

.landing-button-language-container {
  display: flex;
  justify-content: left;
  gap: 10px;
  margin-top: -10px;
  width: 100%;
}

.landing-button {
  background-color: white;
  color: black;
  border: 2px solid black;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  border-radius: 5px;
  height: 100%;
}

/*.landing-button:hover {
  background-color: black;
  color: white;
}*/

.bottom-section {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.error-message-container {
  width: 50%;
  display: flex;
  justify-content: center;
  margin: 20px 0;
  margin-bottom: 0px;
}

.error-message {
  background-color: rgba(255, 0, 0, 0.85);
  color: black;
  font-size: 16px;
  padding: 10px;
  border-radius: 5px;
  max-width: 250px;
  margin: 10px auto;
  text-align: center;
  font-weight: bold;
}

.search-result {
  text-align: left;
  max-width: 400px;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}

.scrollable-box {
  height: 300px;
  overflow-y: scroll;
}

.category-data {
  padding-left: 20px;
}