.multi-selection-container {
  display: grid; 
  grid-gap: 5px; 
  justify-content: center; 
  width: 50%; 
}

@media (max-width: 768px) {
  .multi-selection-container {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr)); 
  }
}