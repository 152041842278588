.number-button {
  background-color: white; 
  border: 0.15vw solid black;
  font-size: 2.2vw; 
  cursor: pointer; 
  outline: none; 
  font-weight: bold;
}

.number-button:nth-child(10) {
  grid-column: 2 / 3; 
}