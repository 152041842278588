.start-button-container {
  position: absolute;
  left: 55%;
  top: 35%;
}

.logo-container {
  position: absolute;
  left: 40%;
  top: 45%;
  transform: translate(-50%, -50%);
  height: 30vw;
}

.saturn-image {
  width: auto;
  height: 100%;
}

.start-button {
  background-color: white;
  border: 2px solid black;
  color: black;
  padding: 2vh 2vw;
  font-size: 4vw;
  cursor: pointer;
  outline: none;
  font-weight: bold;
  border-radius: 5px;
  aspect-ratio: 2/1;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-transform: uppercase;
  font-size: 4vw;
  font-weight: bold;
  z-index: 1000;
  text-align: center; 
  word-wrap: break-word; 
}