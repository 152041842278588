.text-box {
  background-color: white; 
  border: 5px solid black; 
  color: black; 
  width: 30%;
  height: 50%;
  padding: 30px;
  font-size: 6vw; 
  cursor: default; 
  outline: none; 
  font-weight: bold;
  text-align: center;
  border-radius: 10px;
}
 
  