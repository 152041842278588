.error-container {
  background-color: rgba(255, 0, 0, 0.85);
  color: black;
  font-size: 1.7vw;
  padding: 10px;
  border-radius: 5px;
  max-width: 250px;
  margin: 10px auto;
  text-align: center;
  font-weight: bold;
}  
