.outer-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.centered-container {
  position: relative;
  width: 70vw; 
  height: 55vh;
}

.button-connector {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
}

.button-connector button {
  border: 3px solid black;
  width: 50px;
  height: 50px;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
