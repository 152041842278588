.stroop-text-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 10px auto;
  border: 5px solid black;
  background-color: white;
  width: 30%;
  height: 50%;
  padding: 3vw;
  font-size: 5vw;
  font-weight: bold;
  text-align: center;
  border-radius: 10px;
  box-sizing: border-box;
}