.end-message {
    background-color: white;
    border: 0.5vw solid black;
    color: black;
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    border-radius: 5vw;
    width: 75%;
    margin-bottom: 20px;
}

.end-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
