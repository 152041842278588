.money-selection-container {
  margin: 10px auto;
  margin-top: 100px;
}

.underscores-money {
  position: absolute;
  display: flex;
  margin-left: 70px;
  margin-top: -50px;
  font-size: 100px;
}

.number-money {
  margin-right: -15px;
  margin-left: 20px;
  letter-spacing: 40px;
}
