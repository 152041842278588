.flash-text-box {
  transform: translateY(100%);
  justify-content: center;
}

.blackout-overlay {
  position: absolute;
  top: 0%;
  left: 0;
  width: 40%; 
  height: 100%; 
  background-color: rgba(128, 128, 128, 1); 
  z-index: 1;
}

.hide {
  display: none;
}

.show {
  display: flex;
  justify-content: center;
}