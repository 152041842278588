.number-selection-container {
  margin: 10px auto;
  margin-top: 100px;
}

.underscores {
  position: absolute;
  display: flex;
  margin-top: -50px;
  font-size: 100px;
}

.number {
  margin-right: 25px;
  margin-left: 18px;
  padding: -100px;
}

.selected-numbers {
  position: absolute;
  display: flex;
  font-size: 50px;
  margin-left: -12px;
}

.number-line{
  margin-left: 37px;
}

.number-pad {
  background-color: white;
  padding: 10px;
  border-radius: 20px;
}

.delete-button {
  background-color: white; 
  border: 0.15vw solid black; 
  font-size: 35px; 
  cursor: pointer; 
  outline: none; 
  font-weight: bold;
  padding: 10px 80px;
  margin-left: 10px;
}