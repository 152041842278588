.image-container.selected::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background:rgba(133, 199, 255, 0.5) ; 
  pointer-events: none;
  z-index: 1; 
}

.image {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  z-index: 0; 
}

/*.image-button:hover::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(133, 199, 255, 0.3); 
  pointer-events: none;
  z-index: 1; 
}*/
